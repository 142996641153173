import React from 'react';
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import {config} from "../config";
import BUTTON_APP_STORE from "../images/button_app_store.svg";
import BUTTON_GOOGLE_PLAY from "../images/button_google_play.svg";
import {sectionLayout, sectionMargin} from "../utils/constants";

function Footer() {
    const {t} = useTranslation()

    return (
        <Row id='footer'
             style={{
                 marginTop: sectionMargin,
                 paddingTop: sectionMargin,
                 backgroundColor: "#09AA24",
                 color: '#FFFFFF'
             }}>
            <Col {...sectionLayout}>
                <Row>
                    <Col style={{textAlign: 'left'}}
                         {...{
                             xs: {span: 24},
                             sm: {span: 10},
                             md: {span: 8},
                         }}>
                        <h3 className='footer-title'
                            style={{color: '#fff'}}>
                            {t('footer.download.title')}
                        </h3>

                        <p className='footer-text'
                           style={{color: '#fff'}}>
                            {t('footer.download.description')}
                        </p>

                        <Row align='middle' style={{marginBottom: sectionMargin}}>
                            <Col style={{marginTop: '2rem'}}
                                 {...{
                                     xs: {span: 10},
                                     md: {span: 10},
                                     xl: {span: 8}
                                 }}>
                                <a href={config.links.appStore}>
                                    <img
                                        style={{width: '100%'}}
                                        src={BUTTON_APP_STORE}
                                        alt="button_app_store"/>
                                </a>
                            </Col>
                            <Col style={{marginTop: '2rem'}}
                                 {...{
                                     xs: {span: 10, offset: 2},
                                     md: {span: 10, offset: 2},
                                     xl: {span: 8, offset: 1}
                                 }}>
                                <a href={config.links.googlePlay}>
                                    <img
                                        style={{width: '100%'}}
                                        src={BUTTON_GOOGLE_PLAY}
                                        alt="button_google_play"/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...{
                        xs: {span: 24, offset: 0},
                        sm: {span: 12},
                        md: {span: 14, offset: 2},
                    }}>
                        <Row style={{textAlign: 'left', marginBottom: sectionMargin}}
                             justify='end'>

                            <Col  {...{
                                xs: {span: 24, offset: 0},
                                sm: {span: 12},
                                md: {span: 14, offset: 2},
                            }}>
                                <h3 className='footer-title'
                                    style={{color: '#fff'}}>
                                    {t('footer.ara.title')}
                                </h3>

                                <FooterLink link={config.links.press} linkText={t('footer.ara.download_press')}/>
                                <FooterLink link={config.links.contact} linkText={t('footer.ara.contact')}/>
                                <FooterLink link={config.links.terms} linkText={t('footer.ara.terms')}/>
                                <FooterLink link={config.links.privacy} linkText={t('footer.ara.conditions')}/>

                            </Col>
                            {/*<Col span={8}>*/}
                            {/*    <h3*/}
                            {/*        style={{color: '#fff'}}>*/}
                            {/*        {t('footer.social.title')}*/}
                            {/*    </h3>*/}

                            {/*    <FooterLink link={config.links.facebook} linkText={t('footer.social.facebook')}/>*/}
                            {/*    <FooterLink link={config.links.twitter} linkText={t('footer.social.twitter')}/>*/}
                            {/*    <FooterLink link={config.links.instagram} linkText={t('footer.social.instagram')}/>*/}
                            {/*</Col>*/}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24}
                 style={{
                     textAlign: 'center',
                     padding: '1.5rem 4rem',
                     backgroundColor: '#000000'
                 }}>
                <p className='footer-text'
                   style={{color: '#fff'}}>
                    {t('footer.copyright')}
                </p>
            </Col>
        </Row>
    );
}

interface FooterLinkInterface {
    link: string
    linkText: string
}

function FooterLink({link, linkText}: FooterLinkInterface) {
    return (
        <a href={link} style={{display: 'block'}}>
            <p className='footer-text'
               style={{color: '#fff'}}>
                {linkText}
            </p>
        </a>
    )
}

export default Footer;
