/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {ReactNode, useEffect} from "react"
import PropTypes from "prop-types"
import NavBar from "./NavBar"
import Footer from "./Footer"
import {I18NextWrapper} from "../loaders/i18nextInit"
import {Loading} from "./Loading"
import Seo from "./seo"
import {goToSection} from "../utils";

interface AppWrapperProps {
    children: ReactNode
}

const AppWrapper = ({children}: AppWrapperProps) => {
    useEffect(() => {
        const sectionId = window.location.hash.substring(1)
        setTimeout(() =>
            goToSection(sectionId), 200)
    }, [])

    return (
        <>
            <I18NextWrapper loading={<Loading/>}>
                <>
                    <header>
                        <NavBar/>
                    </header>
                    <main>
                        {children}
                    </main>
                    <footer>
                        <Footer/>
                    </footer>
                </>
            </I18NextWrapper>
        </>
    )
}

AppWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AppWrapper
